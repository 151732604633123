import clsx from 'clsx';
import type { Hit, BaseHit } from 'instantsearch.js';
import { useState } from 'react';
import type { InGridContent } from 'utils/types/contentfulInGridContent';
import { FilterAppliedMiddleware } from '../algoliaHelpers/FilterAppliedMiddleware';
import { InGridContentItem } from './InGridContentItem';

export type InjectedHitsProps = {
  hits: Array<Hit<BaseHit> | InGridContent>;
  sendEvent?: () => void;
  classNames?: {
    item?: string;
    list?: string;
    loadMore?: string;
    customItem?: string;
  };
  inGridContent?: InGridContent[];
  hitComponent: React.ElementType;
};
export const InjectedHits = (props: InjectedHitsProps) => {
  const { hits, sendEvent, classNames, inGridContent = [] } = props;

  const [hasFiltersApplied, setHasFiltersApplied] = useState(false);

  const handleAlgoliaRefinementChange = (algoliaFiltersApplied: boolean) => {
    if (algoliaFiltersApplied !== hasFiltersApplied) {
      setHasFiltersApplied(algoliaFiltersApplied);
    }
  };

  const originalHits = [...hits];

  /**
   * Inject custom content into the `originalHits` array.
   * - Custom content only gets injected when no filters are applied.
   */
  if (!hasFiltersApplied) {
    const customContent = [...inGridContent];

    customContent
      .sort((itemA, itemB) => itemA.position - itemB.position)
      .forEach((item) => {
        if (item.position > originalHits.length) return;

        item.objectID = `injected-content-${JSON.stringify(item.content)}`;
        originalHits.splice(item.position, 0, item);
      });
  }

  return (
    <div className="ais-InfiniteHits">
      <ol className={clsx('ais-InfiniteHits-list', classNames?.list)}>
        {originalHits.map((hit) => {
          if (hit.custom) {
            const customContentHit = hit as InGridContent;
            const { mobile: mobileSize = 1, desktop: desktopSize = 1 } =
              customContentHit.size;
            const mobileColClass = `xs${mobileSize * 2}`;
            const desktopColClass = `l${desktopSize * 3}`;

            return (
              <li
                key={customContentHit.objectID}
                className={clsx(
                  'col',
                  mobileColClass,
                  desktopColClass,
                  classNames?.customItem
                )}
              >
                <InGridContentItem hit={customContentHit} />
              </li>
            );
          }

          return (
            <li key={hit.objectID} className={classNames?.item}>
              <props.hitComponent hit={hit} sendEvent={sendEvent} />
            </li>
          );
        })}
      </ol>

      {Boolean(inGridContent.length) && (
        <FilterAppliedMiddleware
          setAlgoliaRefinement={handleAlgoliaRefinementChange}
        />
      )}
    </div>
  );
};
