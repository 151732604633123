import type { SearchClient } from 'algoliasearch/lite';
import { useRouter } from 'next/router';
import { Configure, Index, InstantSearch, useStats } from 'react-instantsearch';
import { useContext } from 'use-context-selector';
import { isChLocale, storeLocale } from '@/root/constants';
import { InstantSearchList } from 'components/InstantSearchList/InstantSearchList';
import type { AlgoliaProductHit } from 'components/productCardHit/ProductCardHit';
import { ProductCardHit } from 'components/productCardHit/ProductCardHit';
import styles from 'components/shelf/Shelf.module.scss';
import { algoliaProductIndex } from 'utils/algoliaConstants';
import type { InGridContent } from 'utils/types/contentfulInGridContent';

import { CustomerContext } from '../customerContext/CustomerContext';
import { useFeatureFlags } from '../featureFlagsProvider/FeatureFlagsContext';
import type { ShelfType } from '../plpContext/types/storePLP';

interface ShelfProps extends ShelfType {
  /**
   * Collection handle
   * - Used to pre-filter shelf to a specific handle.
   * - Passed from the parent component to receive the current handle.
   */
  collectionHandle?: string;
  keyHandle?: string;

  /**
   * Algolia Search Client instance
   * - used to initialise Algolia components
   */
  searchClient: SearchClient;

  asIndex: boolean;
}

/**
 *  Generate the pro tag filter string - used to filter pro products
 *  at current specification de and jp locales doesn't need pro differentiation - every customer sees all products
 * @param isProProductFilteringEnabled flag to enable pro product filtering
 * @param proCustomer is the customer a pro customer
 * @param baseFilter base filter to build on
 * @returns combined filter string
 */
export const generateProTagFilter = (
  isProProductFilteringEnabled: boolean,
  proCustomer: boolean,
  baseFilter: string
) => {
  if (isProProductFilteringEnabled) {
    return proCustomer ? `${baseFilter}tags:Pro` : `${baseFilter}NOT tags:Pro`;
  }
  return `${baseFilter} tags:Pro OR NOT tags:Pro`;
};

export const Shelf = ({
  collectionHandle,
  filters,
  franchiseBlock,
  searchClient,
  inGridContent,
  fallbackCollection,
  asIndex,
  keyHandle,
}: ShelfProps) => {
  const locale = storeLocale(useRouter().locale);
  const { proCustomer } = useContext(CustomerContext);
  const { featureFlags } = useFeatureFlags();
  const { isProProductFilteringEnabled } = featureFlags[locale];
  const handle = collectionHandle ?? fallbackCollection ?? '';
  const collectionsFilter = handle
    ? `collections:${isChLocale(locale) ? keyHandle : handle} AND `
    : ``;

  /**
   * Initialise the filter string to scope the Algolia results
   * - Filtered by current collection (based on passed `collectionHandle`)
   * - Filtered by Pro/not Pro based on whether the customer is pro or not
   */

  const baseFilters = generateProTagFilter(
    isProProductFilteringEnabled,
    proCustomer,
    collectionsFilter
  );
  /**
   * If `filters` prop is passed, add those filters to the `baseFilters`
   */
  const fullBaseFilters = filters
    ? `${baseFilters} AND ${filters}`
    : baseFilters;

  /**
   * CH locale has no pro accounts, so we disable base filters, just filters and collection filter
   */
  const chLocaleFilters = `${collectionsFilter}${filters}`;

  /**
   * disable base filters for CH locales, because of no pro accounts
   */
  const shelfFilters = isChLocale(locale) ? chLocaleFilters : fullBaseFilters;

  return (
    <div className={styles.shelf}>
      <div className={styles.shelf__products}>
        <InstantSearchComponent
          searchClient={searchClient}
          baseFilters={shelfFilters || ''}
          collectionHandle={handle}
          franchiseBlock={franchiseBlock}
          inGridContent={inGridContent}
          asIndex={asIndex}
        />
      </div>
    </div>
  );
};

/**
 * Props for InstantSearchComponent
 * - Uses `Pick` to grab the typescript types from the parent Shelf component
 *   prop types that are passed through unchanged
 * - Adds additional types unique to this component
 */
interface InstantSearchComponentProps
  extends Pick<
    ShelfProps,
    | 'searchClient'
    | 'franchiseBlock'
    | 'inGridContent'
    | 'collectionHandle'
    | 'asIndex'
  > {
  /**
   * Filter string to pass to Algolia
   */
  baseFilters: string;
}

/**
 * Wrapper component to add InstantSearch Algolia component around the content
 * - Used so that we can use Algolia hooks inside the ShelfContent component
 */
const InstantSearchComponent = (props: InstantSearchComponentProps) => {
  const { locale: routerLocale } = useRouter();
  const locale = storeLocale(routerLocale);

  const { searchClient, asIndex } = props;

  if (asIndex) {
    return (
      <Index
        indexName={algoliaProductIndex(locale)}
        indexId={props.baseFilters.toString()}
      >
        <ShelfContent {...props} />
      </Index>
    );
  }
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={algoliaProductIndex(locale)}
    >
      <ShelfContent {...props} />
    </InstantSearch>
  );
};

const ShelfContent = ({
  baseFilters,
  collectionHandle,
  franchiseBlock,
  inGridContent,
}: InstantSearchComponentProps) => {
  const { nbPages, page } = useStats();

  const productsPerPage = 8;
  const isLastPage = nbPages === 0 || nbPages === page + 1;
  const isOnlyPage = nbPages <= 1;
  const visibleProducts = productsPerPage * (page + 1);

  const allInGridContent = getInGridContent({ franchiseBlock, inGridContent });

  const sortedInGridContent = allInGridContent.sort(
    (itemA, itemB) => itemA.position - itemB.position
  );

  /**
   * Calculate how many grid spaces in the in grid content takes up.
   */
  const inGridContentSize = getInGridContentTotalWidth(
    sortedInGridContent,
    visibleProducts
  );

  /**
   * Calculate whether the in grid content will cause incomplete rows in the
   * product grid
   */
  const productsToHide = inGridContentSize % 4;
  const shouldHideCards = productsToHide > 0 && !isOnlyPage && !isLastPage;

  return (
    <div data-hide-cards={shouldHideCards} data-hidden-cards={productsToHide}>
      <Configure
        filters={baseFilters}
        hitsPerPage={productsPerPage} // will show 10 items
        ruleContexts={['shelf']}
        distinct
      />

      <InstantSearchList
        hitComponent={({ hit }) => (
          <ProductCardHit
            hit={hit as unknown as AlgoliaProductHit}
            collectionHandle={collectionHandle}
          />
        )}
        itemClassName="col xs2 l3"
        inGridContent={allInGridContent}
        collectionHandle={collectionHandle}
        hideNoResults={true}
      />
    </div>
  );
};

/**
 * Process all in grid content for the Shelves
 */
const getInGridContent = ({
  franchiseBlock,
  inGridContent,
}: {
  franchiseBlock?: ShelfProps['franchiseBlock'];
  inGridContent: ShelfProps['inGridContent'];
}): InGridContent[] => {
  const allInGridContent: InGridContent[] = [];

  if (franchiseBlock) {
    const data: InGridContent = {
      custom: true,
      position: 0,
      type: 'CollectionIntroCard',
      content: { ...franchiseBlock },
      size: {
        mobile: 2,
        desktop: 1,
      },
    };

    allInGridContent.push(data);
  }

  if (inGridContent) {
    allInGridContent.push(...inGridContent);
  }

  return allInGridContent;
};

const getInGridContentWidth = (inGridItem: InGridContent) => {
  const isDesktop =
    typeof window !== undefined &&
    window.matchMedia('(min-width: 1024px)').matches;

  const sizeKey: keyof InGridContent['size'] = isDesktop ? 'desktop' : 'mobile';

  if (inGridItem.size[sizeKey]) {
    const size = inGridItem.size[sizeKey];

    /**
     * Apply modulo to the size to return 0 when the content spans all columns
     * - Fixes issue where lots of product cards are removed due to large in
     * . grid content size.
     */
    return isDesktop ? size % 4 : size % 2;
  }

  return 0;
};

const getInGridContentTotalWidth = (
  inGridContent: InGridContent[],
  hitsToShow: number
) => {
  return inGridContent.reduce((total, inGridItem) => {
    const itemWidth = getInGridContentWidth(inGridItem);

    /**
     * If in grid item won't fit on this page, exclude from calculation
     */
    if (inGridItem.position > hitsToShow - itemWidth) {
      return total;
    }

    return total + itemWidth;
  }, 0);
};
