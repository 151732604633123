/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable sonarjs/no-duplicate-string */

import type { StoreLocale } from '@/root/constants';
import { DEFAULT_LOCALE, localeToStore } from '@/root/constants';
import { STORES_TO_PRIVATE_TOKEN } from './constants';

const shopifyVersion =
  process.env.STOREFRONT_RELEASE ??
  process.env.NEXT_PUBLIC_STOREFRONT_RELEASE ??
  'unstable';

export const adminDataSource = (
  locale: StoreLocale = DEFAULT_LOCALE
): {
  endpoint: string;
  fetchParams: RequestInit;
} => {
  const store = localeToStore(locale);

  return {
    endpoint: `https://${store}.myshopify.com/admin/api/${shopifyVersion}/graphql.json`,
    fetchParams: {
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Access-Token': STORES_TO_PRIVATE_TOKEN.get(store) ?? '',
      },
    },
  };
};
