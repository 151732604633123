import { CollectionIntroCard } from '@we-make-websites/ui-lib';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import type { InGridContent } from 'utils/types/contentfulInGridContent';
import styles from './InGridContentItem.module.scss';

/**
 * Define dynamic component imports.
 * - Used to dynamically import less-critical components.
 * - Critical components, like the collection intro card, shown as the first
 *   grid card, should be imported as normal.
 */
const PLPPromo = dynamic(() =>
  import('@we-make-websites/ui-lib/src/modules/shop/plpPromo/PLPPromo').then(
    (mod) => mod.PLPPromo
  )
);
const ShareYourLooks = dynamic(() =>
  import(
    '@we-make-websites/ui-lib/src/modules/shop/shareYourLooks/ShareYourLooks'
  ).then((mod) => mod.ShareYourLooks)
);
const VideoPlayer = dynamic(() =>
  import(
    '@we-make-websites/ui-lib/src/components/utility/videoPlayer/VideoPlayer'
  ).then((mod) => mod.VideoPlayer)
);

const ProductRecommendations = dynamic(() =>
  import('../productRecommendations/ProductRecommendationsWrapper').then(
    (mod) => mod.ProductRecommendations
  )
);

const GetLookSection = dynamic(() =>
  import(
    '@we-make-websites/ui-lib/src/modules/getLookSection/GetLookSection'
  ).then((mod) => mod.GetLookSection)
);

export const InGridContentItem = ({ hit }: { hit: InGridContent }) => {
  switch (hit.type) {
    case 'CollectionIntroCard': {
      return <CollectionIntroCard {...hit.content} />;
    }
    case 'PLPPromo': {
      return <PLPPromo {...hit.content} />;
    }
    case 'ShareYourLooks': {
      return <ShareYourLooks {...hit.content} />;
    }
    case 'VideoPlayer': {
      return (
        <div
          className={clsx(
            styles.inGridContentItem,
            styles['inGridContentItem--VideoPlayer']
          )}
        >
          <VideoPlayer {...hit.content} />
        </div>
      );
    }
    case 'ManualProductRecommendations': {
      return <ProductRecommendations {...hit.content} />;
    }
    case 'GetLookSection': {
      return <GetLookSection {...hit.content} />;
    }
    default: {
      return null;
    }
  }

  // displayHit._component = inGridContentComponents[hit.type];
  // const HitComponent = inGridContentComponents[hit.type];

  // const content = displayHit.content as ComponentProps<typeof HitComponent>;
  // if (!displayHit._component) {
  //   return null;
  // }

  // return (
  //   <li key={displayHit.objectID} className={displayHit.size}>
  //     <HitComponent {...content} />
  //   </li>
  // );
};
